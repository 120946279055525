<script setup lang="ts">
import type {
  CmsElementImage,
  CmsElementManufacturerLogo,
} from "@shopware-pwa/composables-next";
import { useCmsElementConfig, useUrlResolver } from "#imports";
import { buildUrlPrefix } from "@shopware-pwa/helpers-next";
import { useElementSize } from "@vueuse/core";
import { computed, ref } from "vue";
import type { ElementConfig } from "@shopware-pwa/composables-next";

const props = withDefaults(
    defineProps<{
      content: HasRounded<CmsElementImage | CmsElementManufacturerLogo>;
      containerClasses?: Array<String>
      additionalContainerStyles?: object
    }>(),
    {
      containerClasses: ['cms-element-image', 'position-relative',  'w-100'],
    },
);

type HasRounded<T> = T & {
  config: {
    rounded: ElementConfig<boolean>
  }
}

const { getUrlPrefix } = useUrlResolver();
const {
  containerStyle,
  displayMode,
  imageContainerAttrs,
  imageAttrs,
  imageLink,
  isVideoElement,
  mimeType,
} = useCmsElementImage(props.content);

const { getConfigValue } = useCmsElementConfig(props.content);

const DEFAULT_THUMBNAIL_SIZE = 10;
const imageElement = ref(null);
const { width, height } = useElementSize(imageElement);

function roundUp(num: number) {
  return num ? Math.ceil(num / 100) * 100 : DEFAULT_THUMBNAIL_SIZE;
}

const srcPath = computed(() => {
  const biggestParam =
      width.value > height.value
          ? `width=${ roundUp(width.value) }`
          : `height=${ roundUp(height.value) }`;
  return `${ imageAttrs.value.src }?${ biggestParam }&fit=crop,smart`;
});
const imageComputedContainerAttrs = computed(() => {
  const imageAttrsCopy = Object.assign({}, imageContainerAttrs.value);
  if (imageAttrsCopy?.href) {
    imageAttrsCopy.href = buildUrlPrefix(
        imageAttrsCopy.href,
        getUrlPrefix(),
    ).path;
  }
  return imageAttrsCopy;
});

const rounded = computed(() => {
  return getConfigValue('rounded')
});

const vMapping = {
  'flex-start': 'top',
  'center': 'center',
  'flex-end': 'bottom'
}

const hMapping = {
  'flex-start': 'left',
  'center': 'center',
  'flex-end': 'right'
}

const objectPositionStyle = computed(() => {
  const hConfigValue = getConfigValue('horizontalAlign');
  const vConfigValue = getConfigValue('verticalAlign');
  const h = hMapping[hConfigValue] ?? 'center';
  const v = vMapping[vConfigValue] ?? 'center';

  return { objectPosition: `${h} ${v}` }
})

const containerHeight = computed(() => {
  if (displayMode.value === 'contain') return containerStyle.value.maxHeight
  if (props.additionalContainerStyles?.height) return props.additionalContainerStyles.height

  return undefined
})

if (displayMode.value === 'standard') {
  containerStyle.value.minHeight = undefined
}


</script>
<template>
  <component
      v-if="imageAttrs.src"
      :class="[
        props.containerClasses,
        {
          'h-100': displayMode !== 'contain' && !props.additionalContainerStyles?.height && displayMode !== 'cover',
        },
        `display-mode--${displayMode}`
      ]"
      :is="imageLink.url ? 'a' : 'div'"
      :style="{
        ...containerStyle,
        height: containerHeight,
        ...additionalContainerStyles
      }"
      v-bind="imageComputedContainerAttrs"
  >
    <video
        v-if="isVideoElement"
        controls
        :class="{
          'h-100 w-100': true,
          'absolute inset-0': ['cover', 'stretch'].includes(displayMode),
          'object-cover': displayMode === 'cover',
        }"
    >
      <source :src="imageAttrs.src" :type="mimeType"/>
      Your browser does not support the video tag.
    </video>
    <img
        v-else
        ref="imageElement"
        loading="lazy"
        :class="{
          'w-100': true,
          'h-100': displayMode !== 'standard',
          'position-absolute inset-0': ['cover', 'stretch'].includes(displayMode),
          'object-cover': displayMode === 'cover',
          'object-contain': displayMode === 'contain',
          'rounded-xxl': rounded
        }"
        :style="{
          maxHeight: displayMode === 'contain' ? '100%' : undefined,
          ...objectPositionStyle
        }"
        :alt="imageAttrs.alt"
        :src="srcPath"
        :srcset="imageAttrs.srcset"
    />
  </component>
</template>
